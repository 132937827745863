import * as Promise from "bluebird";
import {IColumn} from "./search/columns";
import { Base64 } from 'js-base64';
import * as _ from 'lodash';
import {IKeUser} from "./sf/results";

export interface IPermissions{
  serpFilter: boolean,
  ctr_opportunity: boolean,
  kw_potential: boolean,
  seo_difficulty: boolean
}

export interface IConfig {
  allColumns: IColumn[],
  lastListAddedTo: number,
  auth: {
    authenticated: boolean,
    subscribed: boolean,
    user: null | {
      id: number,
      plan: any,
      isKeUser: boolean,
      keSubscription: {
        expiryDate: string,
        plan: string
      } | null,
      isSubscribed: boolean,
      alwaysUsePreferredCountry: boolean,
      hasExpiredSubscription: boolean,
      preferredCountry: {
        id: number,
        code: string,
        name: string
      }
    },
    keUser?: IKeUser
  },
  rowLimit: number,
  blockedSearchRestartTime: number,
  ui: {
    permissions: IPermissions,
    keywordLimit?: number,
    trendDirection: string
  },
  selectMultipleCountries: boolean,
  selectMultipleApis: boolean,
  showAllMetricsToFreeUsers: boolean,
  recaptcha: {
    enable: boolean,
    site_key: string
  },
  planMultiKeywordSearchLimit: number,
  bigExportThreshold: number,
  globalNotifications?: {
    title: string,
    description: string,
    color: string,
    bootstrapColor: string
  }[]
}

export function getConfig(): Promise<IConfig> {
  let res = window.kk;
  if(res.globalNotifications){
    res.globalNotifications = _.map(res.globalNotifications, n => JSON.parse(Base64.decode(n)));
  }
  console.log({
    res
  })
  return Promise.resolve(res);
}

export default getConfig;