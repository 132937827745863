import * as $ from "jquery";
import * as Promise from "bluebird";
import * as _ from 'lodash';

import {IFilters} from "../search/filters";
import {
    KeywordTypeCodes, KeywordType
} from "../../../keyword/keyword-types";
import {IPermissions} from "../config";

export interface IPagination {
    first_index: number,
    have_to_paginate: boolean,
    is_first_page: boolean,
    is_last_page: boolean,
    nb_results: number,
    last_page: number,
    last_index: number,
    current_page: number
}

export interface IKeywordResult {
    __ui_selected: boolean,
    // __ui_selected_r: boolean,
    result_id: number,
    search_result: string,
    monthly_volume: number,
    trend: string,
    cpc: number,
    value: number,
    apis: string[],
    languages: string[],
    countries: string[],
    types: string[],
    has_snippet: boolean,
    has_knowledge_graphs: boolean,
    total_ads_bottom: number,
    total_local: number,
    total_videos: number,
    total_images: number,
    total_fresh: number,
    total_news: number,
    total_pla: number,

    [key: string]: any
}

export interface IColumn {
    name: string,
    description: string,
    id: string
}

export interface IKeUser {
    id: number,
    api_key: string,
    credits: number,
    purchase_link_info: {
        credits_purchase_link: string,
        subscription_purchase_link: string,
        purchase_link: string,
        purchase_link_type: 'credits' | 'subscription'
    }
}

export interface IResponse {
    results: IKeywordResult[],
    pagination: IPagination,
    columns: IColumn[],
    sort_column: string,
    sort_order: 'asc' | 'desc',
    country: string,
    status?: string,
    progress?: number,
    currency: {
        rate: number,
        symbol: string
    },
    perm?: IPermissions,
    maxResults?: number,
    ke_user?: IKeUser
}

export function defaultPagination(): IPagination {
    return {
        first_index: 0,
        have_to_paginate: false,
        is_first_page: true,
        is_last_page: true,
        nb_results: 0,
        last_page: 1,
        last_index: 0,
        current_page: 1
    };
}

export interface IParams {
    id: number,
    page: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: "desc" | "asc",
    country: string,
    filters: IFilters,
    keywordFilter: string,
    strictMode: boolean,
    negativeKeywords: string[],
    preventSorting: boolean,

    //Applies to bulk uploads
    keywordTypes: KeywordType[]
}

export function addResultParams(data: { [name: string]: string }, resultParams: Partial<IParams>) {
    let keywordTypes = null;
    if (resultParams.keywordTypes) {
        keywordTypes = _.map(resultParams.keywordTypes, (rt) => {
            return KeywordTypeCodes[rt];
        });
    }
    data = _.assign({}, data, {
        page: resultParams.page,
        page_size: resultParams.pageSize,
        country: resultParams.country,
        sort_column: resultParams.sortColumn,
        sort_order: resultParams.sortOrder,
        keyword_filter: resultParams.keywordFilter,
        strict_mode: resultParams.strictMode,
        filters: resultParams.filters ? JSON.stringify(resultParams.filters) : null,
        negative_keywords: resultParams.negativeKeywords ? JSON.stringify(resultParams.negativeKeywords) : null,
        keyword_types: keywordTypes ? JSON.stringify(keywordTypes) : null,
        prevent_sorting: resultParams.preventSorting
    });
    return data;
}

export class ResultsFetcher {

    constructor(protected params: Partial<IParams>) {

    }

    getResultsUrl() {
        return `/sf/results/${this.params.id}`;
    }

    results(): Promise<IResponse> {
        let url = this.getResultsUrl();
        let data: { [name: string]: string } = {
            __csrf_token: $('meta[name="csrf-token"]').attr('content')
        };
        data = addResultParams(data, this.params);
        return new Promise((resolve, reject) => {
            $.ajax({
                url: url,
                method: "POST",
                data: data,
                success: (response: {
                    error: boolean,
                    code?: string,
                    message?: string,
                    data: IResponse
                }) => {
                    if (!response.error) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                },
                error: (jqXHR: { responseJSON: { error: boolean, message: string } }) => {
                    // reject(new Error(`${url} returned an error: ${jqXHR.responseJSON.message}`));
                    console.log('jqXHR.responseJSON', jqXHR.responseJSON)
                    reject(jqXHR.responseJSON);
                }
            } as JQueryAjaxSettings);
        });
    }
}

export function results(params: Partial<IParams>): Promise<IResponse> {
    let fetcher = new ResultsFetcher(params);
    return fetcher.results();
}

export default results;
